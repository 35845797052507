.site-background {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.stars,
.twinkling,
.clouds {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.stars {
  background: var(--background-color4) url(/images/stars.png) repeat top center;
  z-index: 0;
}

.twinkling{
  background: transparent url(/images/twinkling.png) repeat top center;
  z-index: 1;
  -moz-animation: move-twink-back 200s linear infinite;
  -ms-animation: move-twink-back 200s linear infinite;
  -o-animation: move-twink-back 200s linear infinite;
  -webkit-animation: move-twink-back 200s linear infinite;
  animation: move-twink-back 200s linear infinite;
}

.clouds {
  background: transparent url(/images/clouds.png) repeat top center;
  z-index: 3;
  -moz-animation: move-clouds-back 200s linear infinite;
  -ms-animation: move-clouds-back 200s linear infinite;
  -o-animation: move-clouds-back 200s linear infinite;
  -webkit-animation: move-clouds-back 200s linear infinite;
  animation: move-clouds-back 200s linear infinite;
}

@keyframes move-twink-back {
  from { background-position: 0 0; }
  to { background-position: -625rem 312.5rem; }
}
@-webkit-keyframes move-twink-back {
  from {background-position: 0 0;}
  to {background-position: -625rem 312.5rem;}
}
@-moz-keyframes move-twink-back {
  from {background-position: 0 0;}
  to {background-position: -625rem 312.5rem;}
}
@-ms-keyframes move-twink-back {
  from {background-position: 0 0;}
  to {background-position: -625rem 312.5rem;}
}

@keyframes move-clouds-back {
  from {background-position: 0 0;}
  to {background-position: 625rem 0;}
}
@-webkit-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position: 625rem 0;}
}
@-moz-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position: 625rem 0;}
}
@-ms-keyframes move-clouds-back {
  from {background-position: 0;}
  to {background-position: 625rem 0;}
}