.profile-dec {
  margin-bottom: 3rem;
}

.profile-img {
  margin: auto;
  margin-bottom: 2rem;
  max-width: 12.5rem;
}

.my-skills {
  text-align: center;
  margin: auto;
}

.skill-category {
  min-width: 8.125rem;
  padding-top: 1.25rem;
  margin: auto;
  -webkit-text-decoration-color: var(--text-color3); /* Safari */    
  text-decoration-color: var(--text-color3);
}

.let-me-know {
  color: var(--text-color3);
  margin-left: 1rem;
}

@media screen and (min-width: 40rem) {
  .about-me {
    display: flex;
  }

  .profile-dec,
  .my-skills {
    flex: 1;
  }

  .profile-dec {
    margin-right: 1rem;
  }
}