footer {
  background-color: var(--background-color3);
  box-sizing: border-box;
  color: var(--text-color);
  display: flex;
  font-size: 1.375rem;
  height: 12.5rem;
  justify-content: space-between;
  text-align: center;
  padding: 2rem 0.625rem;
}

footer a {
  text-decoration: none;
  margin: 0 0.5rem;
}

footer a:link,
footer a:visited { 
  color: var(--text-color3);
}

footer a:hover {
  color: var(--text-color2);
  text-decoration: underline;
}

@media screen and (min-width: 56.25rem){
  footer {
    padding: 2rem 2.5rem;
  }
}