:root {
  --text-color: rgb(255, 255, 255);
  --text-color2:  rgb(86, 86, 217);
  --text-color3:  rgb(153, 84, 199);
  --text-color4: rgb(204, 14, 14);
  --text-color5: rgb(0, 119, 181);
  --text-color6: rgb(16, 2, 31);
  --background-color: rgba(0, 0, 0, 0);
  --background-color2: rgba(0, 0, 0, 0.1);
  --background-color3: rgba(48, 48, 48, 0.7);
  --background-color4: rgb(0, 0, 0);
  --background-color5: rgba(232, 232, 232, 0.75);
  --background-color6: rgba(0, 0, 0, 0.75);
}

@font-face { 
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/ubuntu/ubuntu-v14-latin-regular.eot');
  src: local('Ubuntu Regular'),
      local('Ubuntu-Regular'),
      url('/fonts/ubuntu/ubuntu-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/ubuntu/ubuntu-v14-latin-regular.woff2') format('woff2'),
      url('/fonts/ubuntu/ubuntu-v14-latin-regular.woff') format('woff'),
      url('/fonts/ubuntu/ubuntu-v14-latin-regular.ttf') format('truetype'),
      url('/fonts/ubuntu/ubuntu-v14-latin-regular.svg#Ubuntu') format('svg');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/ubuntu/ubuntu-v14-latin-700.eot');
  src: local('Ubuntu Bold'),
      local('Ubuntu-Bold'),
      url('/fonts/ubuntu/ubuntu-v14-latin-700.eot?#iefix') format('embedded-opentype'),
      url('/fonts/ubuntu/ubuntu-v14-latin-700.woff2') format('woff2'),
      url('/fonts/ubuntu/ubuntu-v14-latin-700.woff') format('woff'),
      url('/fonts/ubuntu/ubuntu-v14-latin-700.ttf') format('truetype'),
      url('/fonts/ubuntu/ubuntu-v14-latin-700.svg#Ubuntu') format('svg');
}

@font-face {
  font-family: 'fontello';
  src: url('/fonts/fontello/fontello.eot?88044593');
  src: url('/fonts/fontello/fontello.eot?88044593#iefix') format('embedded-opentype'),
       url('/fonts/fontello/fontello.woff?88044593') format('woff'),
       url('/fonts/fontello/fontello.ttf?88044593') format('truetype'),
       url('/fonts/fontello/fontello.svg?88044593#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
}

body,
html {
  height: 100%;
  font-family: 'Ubuntu', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}