.container {
  background-color: var(--background-color5);
  box-sizing: border-box;
  color: var(--text-color6);
  padding: 3rem 1rem;
  font-size: 1.5rem;
  max-width: 85rem;
  margin: auto;
  margin-bottom: 6rem;
}

.sectionTitle {
  text-align: center;
  margin-bottom: 5.625rem;
  margin-top: 0;
  font-size: 3rem;
  width: 100%;
}

@media screen and (min-width: 40rem) {
  .container { 
    padding: 4rem 1.5rem;
  }
}

@media screen and (min-width: 56.25rem){
  .container { 
    padding: 6rem 2rem;
  }
}