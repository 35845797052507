.app-header {
  box-sizing: border-box;
  color: var(--text-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: absolute;
  overflow: hidden;
  -webkit-transition: all .5s;
  transition: all .5s; 
  width: 100%;
  z-index: 1;
}

.app-header a:link,
.app-header a:visited { 
  color: var(--text-color);
}

.nav-log-container {
  background-color: var(--background-color6);
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.sticky {
  position: fixed;
  top: 0;
}

.logo h1 {
  font-size: 2rem;
  margin: 0;
}

.toggle-hamburger {
  font-size: 2rem;
}

.nav-button {
  background-color: var(--background-color);
  border: none;
  color: var(--text-color);
  display: block;
  font-size: 1.875rem;
  padding: 0.5rem 0.3125rem;
  cursor: pointer;
  width: 100%;
}

.nav-button:hover {
  color: var(--text-color2);
  text-decoration: underline;
}

.mobile-nav-btn {
  background-color: var(--background-color);
  border: none;
  color: var(--text-color);
  cursor: pointer;
  padding: 0.625rem;
}

.navbar {
  background-color: var(--background-color6);
  color: var(--text-color);
  text-align: right;
  right: 0;
  top: 0;
  transition: all .5s;
  -webkit-transition: all .5s;
  width: 100%;
}

.hide {
  transform: translateX(100%);
}

@media screen and (min-width: 37.5rem) {
  .toggle-hamburger {
    display: none;
  }

  .app-header {
    background-color: var(--background-color6);
    flex-wrap: nowrap;
  }

  .app-header:not(.sticky) {
    background-color: var(--background-color2);
  }

  .nav-log-container {
    background: none;
  }

  .navbar {
    background: none;
    display: flex;
    width: auto;
  }

  .hide {
    transform: initial;
  }

  .nav-button {
    padding: 0.625rem;
  }

} 


@media screen and (min-width: 56.25rem){
  .app-header {
    padding: 0 2.5rem;
  }
}