.greeting {
  box-sizing: border-box;
  background-color: var(--background-color2);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-top: 40vh;
  position: relative;
  text-align: center;
  height: 100vh;
}

.statementGreeting {
  color: var(--text-color);
}

.contactGreeting {
  display: flex;
  align-items: center;
  justify-content: center
}

.contactLink {
  margin: 0.625rem;
  transition: all ease-out 0.3s;
}

.contactLink:hover {
  transform: scale(1.2);
}

.contactLink:active {
  transform: scale(1);
}

.statementGreeting{
  font-size: 2em;
}

.greeting-icon {
  display: block;
  font-size: 2.75rem;
  margin-bottom: 0.25rem;
}

.greetingIcons { 
  display: block;
  text-decoration: none;
}

.contactLink:nth-of-type(1) > .greetingIcons{
  color: var(--text-color5);
}
 
.contactLink:nth-of-type(2) > .greetingIcons{
  color: var(--text-color);
}

.contactLink:nth-of-type(3) > .greetingIcons{
  color: var(--text-color4);
}
 
.contactLink:nth-of-type(4) > .greetingIcons{
  color: var(--text-color2);
}
 
@media screen and (min-width: 37.5rem) {
  .greeting-icon {
    font-size: 3.75rem;
  }
} 