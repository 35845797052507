.project-temp {
  color: var(--text-color6);
  text-align: center;
  max-width: 56.25rem;
  margin: auto;
  margin-bottom: 9rem;
}

.project-temp h3 {
    margin: 1rem;
    font-size: 1.5em;
}

.project-img {
    width: 100%;
}

.project-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;  
}

.btn {
  background-color: var(--text-color);
  border: 0.125rem solid var(--text-color3);
  box-shadow: 0.125rem 0.125rem rgba(207, 139, 252, 0.39);
  color: var(--text-color3);
  cursor: pointer;
  font-size: 2rem;
  flex: 1;
  min-width: 15rem;
  margin: 0.5rem;
  padding: 0.25rem 1rem;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
}

.btn:hover {
  box-shadow: 0.125rem 0.125rem var(--text-color3);
}

.btn:active {
  box-shadow: none;
}

.disabled-btn {
  background-color: var(--text-color);
  border: 0.125rem solid var(--text-color3);
  color: var(--text-color3);
  font-size: 2rem;
  flex: 1;
  min-width: 15rem;
  margin: 0.5rem;
  opacity: 0.7;
  padding: 0.25rem 1rem;
  text-decoration: none;
  width: 100%;
}

.btnMaxWidth {
  display: block;
  margin: auto;
  max-width: 56.25rem;
}